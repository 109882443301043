import request from "@/utils/request";



// 区域
export function provinceRegionIdCount(params) {
  return request({
    // url: "/v2/api/app/ad_user//screen/provinceRegionIdCount",
    // url: "/v2/api/app/ad_user//screen/query_region_tree",
    url: '/v2/api/app/ad_user//screen/query_region_other',
    method: "get",
    params,
  });
}
// 阵地建设
export function positionCount(params) {
  return request({
    url: "/v2/api/app/ad_user//screen/positionCount",
    method: "get",
    params,
  });
}

// 志愿服务总时长
export function volunteerTime(params, data) {
  return request({
    url: "/v2/api/app/ap_member//screen/provinceRegionIdMember",
    method: "get",
    data,
    params,
  });
}

// 志愿者数量统计
export function volunteerNumberPeople(params) {
  return request({
    url: "/v2/api/app/ap_member//screen/query/activity/member/member_counts",
    method: "get",
    params,
  });
}

// 市区数据
export function provinceRegionIdCountOne(params) {
  return request({
    url: "/v2/api/app/ad_user//screen/provinceRegionIdCountOne",
    method: "get",
    params,
  });
}

// 活动开展统计
export function activityPeople(params) {
  return request({
    url: "/v2/api/app/ad_activity//screen/query/activity/member/activity_time_count",
    method: "get",
    params,
  });
}

//进行中活动或者招募活动
export function activityCount(params) {
  return request({
    // url: "/v2/api/app/ad_activity//screen/query/activity/member/regionid_count",
    url: "/v2/api/app/ad_activity//screen/query/activity/member/regionid_count/other",
    method: "get",
    params,
  });
}

// GIS阵地分布
export function gisMap(params) {
  return request({
    url: "/v2/api/app/ad_user//screen/provinceRegionIdAppNameCount",
    method: "get",
    params,
  });
}

//活动风采
export function activityShow(params) {
  return request({
    // url: "/v2/api/app/ap_contribution//screen/showHotActivityByRegionId",
    url: "/v2/api/app/ap_contribution//screen/showHotActivityByRegionId_other",
    method: "post",
    params,
  });
}
//根据appName获取经纬度
export function provinceGet(params) {
  return request({
    url: "/v2/api/app/ad_user//screen/provinceRegionIdAppNameCountOther",
    method: "get",
    params,
  });
}
//根据regionID获取经纬度
export function organization(params) {
  return request({
    url: "/v2/api/app/ad_user//screen/organization",
    method: "post",
    params,
  });
}
//群众点单
export function orderCount(params) {
  return request({
    url: "/v2/api/app/ap_member//screen/order_count",
    method: "post",
    params,
  });
}

//群众点单列表
export function selectPeopleOrder(params) {
  return request({
    url: "/v2/api/app/ap_member//screen/selectPeopleOrder",
    method: "get",
    params,
  });
}
//直播
export function lives(params, data) {
  return request({
    url: "/v2/api/app/ap_member//screen/lives",
    method: "post",
    params,
    data
  });
}
//查询正在进行的活动
export function underwayActivity(params) {
  return request({
    url: "/v2/api/app/ad_activity//screen/underway_activity",
    method: "get",
    params,
  });
}

//点击地图获取监控视频
export function regionUrl(data, params) {
  return request({
    url: "/v2/api/app/ad_user//region/select_region_monitoring_byid?regionId=" + params.regionId,
    method: "post",
    data,
    params
  });
}
export function keyxinxi(params) {
  `ad_contribution//column/value/getColumValue`
  return request({
    url: `/v2/api/app/ap_contribution//column/value/app_getColumValue?appName=${params.appName}&columnType=${params.columnType}&sign=appPrefix`,
    method: "get"
  });
}

export default {
  provinceRegionIdCount,
  positionCount,
  volunteerTime,
  volunteerNumberPeople,
  activityPeople,
  gisMap,
  activityCount,
  provinceRegionIdCountOne,
  activityShow,
  provinceGet,
  orderCount,
  selectPeopleOrder,
  lives,
  regionUrl,
  keyxinxi
};

<template>
  <div id="app">
    <router-view />
    <!-- 电话 -->
    <el-dialog
      :fullscreen="fullscreen"
      top="8vh"
      class="mydianhuabox"
      :close-on-click-modal="false"
      :show-close="false"
      @closed="handleClose"
      :visible.sync="dialogVisible"
      width="900px"
    >
      <div slot="title" class="dialoghed">
        <span>视频通话</span>
        <i @click="fullscreen=!fullscreen" class="el-icon-full-screen"></i>
      </div>
      <!-- 提示页面 来电 呼叫中 -->
      <div class="tipsBox" v-show="tipsBox">
        <div class="tipsadd" v-if="callsign==1">
          <img src="./assets/dacall/dengdai.png" alt />
          <span>&nbsp;</span>
          <span>{{otheruseInfor.nick}}</span>
          <span class="tiptext">等待接听</span>
        </div>
        <div class="tipsadd" v-else>
          <img src="./assets/dacall/laidian.png" alt />
          <span>&nbsp;</span>
          <span>{{otheruseInfor.nick}}</span>
          <span class="tiptext">来电提醒</span>
        </div>
      </div>
      <!-- 视频页面 -->
      <div v-show="!tipsBox" class="myvideoPigbox">
        <!-- 我的视频摄像头 -->
        <div class="videoBox" style="margin-right:20px;" id="myvideo"></div>
        <!-- 对方视频摄像头 -->
        <div class="videoBox" id="othervideo"></div>
      </div>
      <div class="buttonBox">
        <el-button v-if="callsign==1" @click="hangup" type="danger" icon="el-icon-phone">挂断</el-button>
        <el-button
          v-if="callsign!=1"
          @click="acceptData"
          type="success"
          icon="el-icon-phone-outline"
        >接听</el-button>
        <el-button v-if="callsign!=1" @click="refuseData" type="danger" icon="el-icon-phone">拒绝</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { keyxinxi } from '@/api/home.js'
// import { TUICallEngine,TUICallEvent } from "tuicall-engine-webrtc"
import { TUICallEngine, TUICallEvent } from 'tuicall-engine-web';
import TIM from 'tim-js-sdk';
import LibGenerateTestUserSig from './assets/lib-generate-test-usersig-es.min.js';
export default {
  data() {
    return {
      fullscreen: false, // 是否全屏
      callsign: 1,  // 1 打电话、接听电话  2请求通话
      tipsBox: true, // 提示信息
      tuiCallEngine: null,
      tim: null,
      otheruseInfor: {},
      nowuseinfor: {}, // 我当前的个人信息
      dialogVisible: false, // 电话弹框
      SDKAPPID: '',
      SECRETKEY: '',
      userID: ''

    }
  },
  computed: mapState(['EXPIRETIME', 'otheruserId']),
  async created() { // 创建实例登录
    await this.qitajiekou(12)
    await this.qitajiekou(13)

    this.tim = TIM.create({
      SDKAppID: this.SDKAPPID
    })
    let options = {
      SDKAppID: this.SDKAPPID, // 接入时需要将 0 替换为您的云通信应用的 SDKAppID
      tim: this.tim     // tim 参数适用于业务中已存在 TIM 实例，为保证 TIM 实例唯一性
    };
    this.tuiCallEngine = TUICallEngine.createInstance(options); // 创建实例
    // 登录
    const generator = new LibGenerateTestUserSig(this.SDKAPPID, this.SECRETKEY, this.EXPIRETIME);
    this.userID = '02022123' + this.$route.query.regionId
    console.log('this.userID', this.userID);
    const userSig = generator.genTestUserSig(this.userID);
    this.tuiCallEngine.login({
      userID: this.userID,
      userSig
    })
    this.tuiCallEngine.setSelfInfo({
      nickName: this.$route.query.regionName + '指挥中心',
    });

    document.title = this.$route.query.regionName + '文明实践指挥调度中心'
    // 电话的一些监听函数
    this.initListener();
  },
  watch: {
    otheruserId(newdata) {
      this.dacallIng(newdata);
    }
  },
  destroyed() {
    this.removeListener();
  },
  methods: {
    qitajiekou(datadd) {
      return new Promise((resolve) => {
        keyxinxi({ columnType: datadd, sign: 'appPrefix', appName: this.$route.query.appName }).then((resson) => {
          if (resson.success) {
            if (datadd === 12) {
              // console.log('this.SDKAPPID==========>', resson.data);
              this.SDKAPPID = Number(resson.data)
            } else {
              this.SECRETKEY = resson.data
            }
          }
          resolve(resson.data)
        })
      })
    },
    // 打电话
    async dacallIng(newdata) {
      if (newdata) {
        var response = await this.tim.getUserProfile({ userIDList: [newdata] });
        this.otheruseInfor = response.data[0]; // 对方的用户信息
      }
      if (newdata && this.callsign == 1) { // 打电话
        if (response.data.length) {
          this.callsign = 1;  // 1 打电话  2请求通话
          this.dialogVisible = true;
          // 打电话
          this.tuiCallEngine.call({
            userID: newdata,  // 用户 ID
            type: 2, // 通话类型，0-未知， 1-语音通话，2-视频通话
          }).then(res => {
            console.log(res);
          }).catch(error => {
            console.warn('打电话失败啦:', error);
          });
        } else {
          this.$message({
            showClose: true,
            message: '该用户还未注册，在手机上登录即可注册！',
            type: 'error'
          });
        }
      }
    },
    handleClose() {
      this.callsign = 1;
      this.$store.commit('otheruserId', '');
      this.tipsBox = true;
    },
    // 挂断电话
    hangup() {
      this.tuiCallEngine.hangup();
      this.dialogVisible = false;
    },
    // 拒绝电话
    refuseData() {
      this.tuiCallEngine.reject().then(res => {
        console.log('我拒绝了', res)
      }).catch(error => {
        console.warn('reject error:', error);
      });
      this.dialogVisible = false;
    },
    // 接听电话
    acceptData() {
      this.tuiCallEngine.accept().then(res => {
        this.callsign = 1;
        this.tipsBox = false;
        console.log('============================我接听了========================', res)
      }).catch(error => {
        console.warn('accept error:', error);
      });
    },

    tipsData(data) {
      this.$message({
        showClose: true,
        message: data,
        type: 'error'
      });
      this.dialogVisible = false;
    },
    initListener() {
      this.tuiCallEngine.on(TUICallEvent.USER_ENTER, () => {
        this.tipsBox = false;
        this.tuiCallEngine.startLocalView({
          userID: this.userID,  //本地用户 ID
          videoViewDomID: 'myvideo', //该用户数据将渲染到该 DOM ID 节点里
          options: {
            objectFit: 'contain'
          }
        })

        this.tuiCallEngine.startRemoteView({
          userID: this.otheruserId,  //本地用户 ID
          videoViewDomID: 'othervideo', //该用户数据将渲染到该 DOM ID 节点里
          options: {
            objectFit: 'contain'
          }
        })
      });

      this.tuiCallEngine.on(TUICallEvent.CALLING_END, () => {
        this.tipsData('通话结束！')
      });

      this.tuiCallEngine.on(TUICallEvent.REJECT, () => {
        this.tipsData('对方已拒绝！')
      });

      // this.tuiCallEngine.on(TUICallEvent.USER_LEAVE, () => {
      //   this.tipsData('对方已离开！')
      // }); 

      this.tuiCallEngine.on(TUICallEvent.LINE_BUSY, () => {
        this.tipsData('对方忙线！')
      });

      this.tuiCallEngine.on(TUICallEvent.NO_RESP, () => {
        this.tipsData('对方无应答！')
      });
      this.tuiCallEngine.on(TUICallEvent.CALLING_TIMEOUT, () => {
        this.tipsData('超时未应答！')
      })

      this.tuiCallEngine.on(TUICallEvent.CALLING_ENDCEL, () => {
        this.tipsData('通话结束！')
      });

      this.tuiCallEngine.on(TUICallEvent.INVITED, (res) => {
        this.callsign = 2;
        this.$store.commit('otheruserId', res.sponsor);
        this.dialogVisible = true;
      });

      this.tuiCallEngine.on(TUICallEvent.CALLING_CANCEL, () => {
        this.tipsData('对方取消通话！')
      });
    },
    removeListener() {
    }
  }
}
</script>

<style lang="less" scoped>
// 电话样式
.mydianhuabox {
  .dialoghed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-icon-full-screen {
      cursor: pointer;
    }
  }
  // ::v-deep .el-dialog{
  //   height: 800px;
  // }
  .tipsBox {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tipsadd {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tiptext {
        margin-top: 10px;
        color: #000;
        font-size: 18px;
      }
    }
  }
  .myvideoPigbox {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    .videoBox {
      width: 421px;
      height: 100%;
      background-color: black;
    }
  }
  .buttonBox {
    margin-top: 36px;
    text-align: center;
  }
}
</style>

<style lang="less" scoped>
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
* {
  margin: 0;
  // padding: 0;
}
</style>

<!--  -->
<template>
  <div class="massOrder">
    <div class="numDiv">
      <div
        class="oneNumDiv"
        v-for="(value,key,index) in massNum"
        :key="index"
        :class="index==1?'numStyle':''"
      >
        <div class="topNum">{{value}}</div>
        <div class="bottomNun">{{key}}</div>
      </div>
    </div>
    <div class="swiperDiv" @mouseenter="autoPlayStop" @mouseleave="autoPlayStart">
      <swiper :options="swiper" v-if="massArray.length" ref="swiperMass">
        <swiper-slide class="swiperContent" v-for="(item,index) in massArray" :key="index">
          <div class="oneMass" v-for="(itemSon,indexSon) in item" :key="indexSon">
            <div>
              <span>活动类型：</span>
              {{itemSon.serviceTypeName|ellipsis}}
            </div>
            <div>
              <span>点单时间：</span>
              {{itemSon.createTime}}
            </div>
            <div>
              <span>反馈状态：</span>
              {{itemSon.status}}
            </div>
            <div>
              <span>点单人员：</span>
              {{itemSon.peopleName|ellipsis}}
            </div>
            <div>
              <span>反馈内容：</span>
              {{itemSon.peopleOrderFeedBack|ellipsis}}
            </div>
            <div>
              <span>活动需求：</span>
              {{itemSon.peopleOrderRemark|ellipsis}}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper/src'
import { orderCount, selectPeopleOrder } from '@/api/home'
export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    cityId: {
      type: Number
    }
  },
  watch: {
    cityId: {
      handler(newVal) {
        if (newVal) {
          this.regionId = newVal
          this.massArray = []
          this.orderCount()
          this.selectPeopleOrder()
        }
      },
      immediate: true,
      deep: true,
    }

  },
  filters: {
    ellipsis(value) {
      if (!value) return '暂无'
      if (value.length > 12) {
        return value.slice(0, 12) + '...'
      }
      return value
    }
  },
  computed: {
    //计算属性
    // 轮播图 swiper对象
    swiperMass() {
      return this.$refs.swiperMass.swiper;
    }
  },
  data() {
    return {
      regionId: 0,
      massNum: null,
      massArray: [],
      timer: null,
      swiper: {
        loop: true,
        observer: true,//修改swiper⾃⼰或⼦元素时，⾃动初始化swiper 
        observeParents: false,//修改swiper的⽗元素时，⾃动初始化swiper 
        autoplay: {
          delay: 3000,
          stopOnLastSlide: true,
          disableOnInteraction: false
        }// 可选选项，自动滑动
      },
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.timer = setInterval(() => {
      this.orderCount()
      this.selectPeopleOrder()
    }, 60000 * 5)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 鼠标放上去停止
    autoPlayStop() {
      this.swiperMass.autoplay.stop();
    },
    // 鼠标离开开始运动
    autoPlayStart() {
      this.swiperMass.autoplay.start();
    },
    orderCount() {
      orderCount({ regionId: this.regionId }).then((res) => {
        if (res.success) {

          this.massNum = res.data
        }
      })
    },
    selectPeopleOrder() {
      selectPeopleOrder({ page: 1, rows: 100, appName: this.$route.query.appName, regionId: this.regionId }).then(res => {
        // console.log('mass', res);
        if (res.success) {
          let conAll = []
          let data = res.data.items
          for (var i = 0; i < data.length; i++) {
            if (data[i].peopleOrderStatus) {
              let status = data[i].peopleOrderStatus
              switch (status) {
                case ('00'): data[i].status = '创建点单'
                  break;
                case ('01'): data[i].status = '派发'
                  break;
                case ('02'): data[i].status = '上报'
                  break;
                case ('03'): data[i].status = '已反馈'
                  break;
                default: data[i].status = '暂无'
                  break;
              }
            }

          }
          for (var j = 0; j < data.length / 2; j++) {

            conAll.push(data.slice(j * 2, j * 2 + 2));
          }
          this.massArray = conAll;
        }
      })
    }
  },
}
</script>
<style scoped lang="less">
.massOrder {
  width: 100%;
  height: 100%;
  background-image: url("../assets/massOrder/massOrder.png");
  background-size: 100% 100%;
  padding-top: 55px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .numDiv {
    width: 589px;
    height: 79px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url("../assets/massOrder/smallBg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    .numStyle {
      border-left: solid 1px #5185d6;
      border-right: solid 1px #5185d6;
    }
    .oneNumDiv {
      width: 196px;
      height: 51px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .topNum {
        font-size: 24px;
        font-family: "numText";
        font-weight: bold;
        color: #ffe4b0;
      }
      .bottomNum {
        font-size: 14px;
        font-family: PingFangHK;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .swiperDiv {
    width: 100%;
    margin-top: 10px;
    .swiperContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      // justify-content: center;
      //
      .oneMass {
        width: 277px;
        height: 180px;
        margin-left: 24px;
        background-image: url("../assets/massOrder/massBg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        box-sizing: border-box;
        padding: 15px 0 0 11px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        // margin-right: 23px;
        div {
          margin-top: 4px;
        }
        span {
          color: #3fc6ed;
        }
      }
    }
  }
}
</style>
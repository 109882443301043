<template>
  <div>
    <div class="Statistics" style="color:white">
      <!-- 志愿者统计数量 -->
      <div class="top">
        <div class="topic">
          <img src="../assets/volunteerTime/topleft.png" alt />
          <p>志愿者数量统计</p>
        </div>
        <div class="right-Three">
          <div class="right-Info">
            <h3>{{info.allCount}}</h3>
            <p>累计注册</p>
          </div>
          <div class="right-Info">
            <h3 style="color:#FF003A;">{{info.allStar}}</h3>
            <p>星级志愿者</p>
          </div>
          <div class="right-Info">
            <h3 style="color:#FFA900;">{{info.allactivite}}</h3>
            <p>活跃人数</p>
          </div>
        </div>
      </div>
      <div class="figure">
        <div id="echart"></div>
      </div>
    </div>
  </div>
</template>
<script>

import { volunteerNumberPeople } from '@/api/home';
export default {
  props: {
    cityId: {
      type: Number,
    }
  },
  data() {
    return {
      info: {},
      //   x轴
      titleData: [],
      //累计数据   
      countData: [],
      //活跃人数
      activiteData: [],
      //星级   
      starData: [],
      timer: null,
      //echarts图表tooltip定时器
      lineTime: null,
      cityshow: 0,
      regionId: 0
    }
  },
  watch: {
    cityId: {
      handler(newVal) {
        if (newVal) {
          this.regionId = newVal
          this.titleData = []
          this.countData = []
          this.activiteData = []
          this.starData = []
          if (newVal === Number(this.$route.query.regionId)) {
            this.cityshow = 2
          } else {
            this.cityshow = 4
          }
          clearInterval(this.lineTime)
          this.getListNumber()
        }
      },
      immediate: true,
      deep: true

    }

  },
  created() {
    // this.getListNumber()
    if (this.regionId === Number(this.$route.query.regionId)) {
      this.cityshow = 2
    } else {
      this.cityshow = 4
    }
  },
  mounted() {
    var _this = this
    _this.timer = setInterval(function () {
      _this.titleData = []
      _this.countData = []
      _this.activiteData = []
      _this.starData = []
      if (_this.regionId === Number(this.$route.query.regionId)) {
        _this.cityshow = 2
      } else {
        _this.cityshow = 4
      }
      clearInterval(_this.lineTime)
      _this.getListNumber()
    }, 300000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.lineTime)
  },
  methods: {
    getListNumber() {
      volunteerNumberPeople({ regionId: this.regionId }).then((res) => {
        if (res.success) {
          // 右上角三条数据
          this.info = res.data.all;
          // 折线图数据处理
          if (res.data.office.length > 0) {
            let tem = res.data.office;
            for (let i = 0; i < tem.length; i++) {
              if (tem[i].officName.indexOf('新时代文明实践站') !== -1) {
                // console.log('officName===>', tem[i].officName, tem[i].officName.substring(0, tem[i].officName.indexOf('新时代文明实践站')))
                let index = tem[i].officName.indexOf('新时代文明实践站')
                tem[i].officName = tem[i].officName.substring(0, index)
              }
              this.titleData.push(tem[i].officName)
              this.countData.push(tem[i].Count)
              this.activiteData.push(tem[i].activite)
              this.starData.push(tem[i].star)
            }
            this.echartsInit()
          } else {
            // 销毁折线图
            this.echarts.init(document.getElementById('echart')).dispose();
          }
        } else {
          this.$message(res.message)
        }

      })
    },
    echartsInit() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      let lineEcharts = this.echarts.init(document.getElementById('echart'))
      lineEcharts.setOption({
        grid: {
          top: 50,
          left: 70,
          bottom: 30,
          right: 50
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {// 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{b} <br>{a0} : {c0}人 <br>{a1} : {c1}人 <br>{a2} : {c2}人',
          backgroundColor: "rgba(51, 181, 255, 0.4)",
          borderWidth: 0,
          textStyle: {
            color: '#fff'
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: '#fff'
            }
          },
          axisLabel: {
            interval: 4,//横轴信息全部显示
            // rotate: 8,//60度角倾斜显示
          },
          boundaryGap: true,
          // data: ['合肥市', '宣城市', '池州市', '六安市', '滁州市', '铜陵市', '宿州市','亳州市']
          data: this.titleData
        },
        yAxis: {
          name: '( 人 )',
          type: 'value',
          axisLine: {
            show: true,    // 是否显示坐标轴轴线
            lineStyle: {
              color: '#fff',    // 坐标轴线线的颜色
              width: '1',    // 坐标轴线线宽
              type: 'solid',     // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
            },
          },
          splitLine: {
            show: true,    // 是否显示分隔线。默认数值轴显示，类目轴不显示
            lineStyle: {
              color: 'rgba(0, 255, 255, 0.2)',
              width: 1
            }
          },
        },
        series: [
          {
            name: "累计注册",
            data: this.countData,
            type: 'line',
            color: "#000",
            // symbol:'roundRect',
            symbolSize: 5,
            itemStyle: {
              // normal: {
              color: "#07DBFF",
              borderColor: '#fff',
              borderWidth: 1,
              // }
            },
          }, {
            name: "星级志愿者",
            data: this.starData,
            type: 'line',
            color: "#000",
            // symbol:'roundRect',
            symbolSize: 5,
            itemStyle: {
              // normal: {
              color: "#FF003A",
              borderColor: '#fff',
              borderWidth: 1,
              // }
            },
          }, {
            name: "活跃人数",
            data: this.activiteData,
            type: 'line',
            color: "#000",
            // symbol:'roundRect',
            symbolSize: 5,
            itemStyle: {
              // normal: {
              color: "#FFA900",
              borderColor: '#fff',
              borderWidth: 1,
              // }
            },
          }
        ],
        color: ['"#07DBFF"', '#FF003A', '#FFA900']
      }, true)

      // 动态显示tootip
      var faultByHourIndex = 0; //播放所在下标
      this.lineTime = setInterval(() => { //使得tootip每隔三秒自动显示
        lineEcharts.dispatchAction({
          type: 'showTip', // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: faultByHourIndex
        });
        faultByHourIndex++;
        // faultRateOption.series[0].data.length 是已报名纵坐标数据的长度
        if (faultByHourIndex > this.titleData.length) {
          faultByHourIndex = 0;
        }
        // if (faultByHourIndex > this.titleData.length) {
        //     faultByHourIndex = 0;
        // }
        // if (faultByHourIndex > this.titleData.length) {
        //     faultByHourIndex = 0;
        // }
      }, 2000);
    },

  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  text-align: center;
}
li {
  list-style: none;
}
.Statistics {
  background-image: url("../assets/position/bigBg.png");
  background-repeat: round;
  width: 100%;
  height: 271px;
  overflow: hidden;
  // padding: 19px 13px 15px;
  // display: flex;
  .top {
    width: 100%;
    height: 46px;
    position: relative;
    .topic {
      width: 300px;
      height: 46px;
      // background-color: rgba(0, 170, 255, 0.3);
      background: linear-gradient(
        to right,
        rgba(0, 170, 255, 0.3),
        transparent
      );
      // background-image: url("../assets/volunteerTime/topleft.png");
      // background-repeat: no-repeat;
      // background-position: 0 5px;
      display: flex;
      p {
        font-size: 22px;
        font-weight: bold;
        line-height: 46px;
        letter-spacing: 2px;
        text-align: left;
        margin-left: 10px;
      }
      img {
        width: 8px;
        height: 35px;
        margin-top: 5px;
        margin-left: 1px;
      }
    }
    .right-Three {
      width: 320px;
      height: 62px;
      position: absolute;
      right: 0;
      top: 8px;
      display: flex;
      .right-Info {
        box-sizing: border-box;
        width: 96px;
        height: 100%;
        // border: 1px solid rgba(0, 255, 255, 0.25);
        background-image: url("../assets/volunteerTime/arrow.png");
        background-repeat: round;
        margin-right: 8px;
        // position: relative;
        h3 {
          width: 100%;
          height: 37px;
          line-height: 37px;
          font-size: 20px;
          font-weight: bold;
          color: #00f5ff;
        }
        p {
          width: 100%;
          height: 25px;
          // background-color: rgba(1, 213, 222, 0.3);
          font-size: 13px;
          font-weight: 500;
          line-height: 25px;
          letter-spacing: 1px;
        }
      }
      .right-Info:nth-child(2) {
        background-image: url("../assets/volunteerTime/arrow2.png");
      }
      .right-Info:nth-child(3) {
        background-image: url("../assets/volunteerTime/arrow3.png");
      }
    }
  }
  .figure {
    width: 100%;
    height: 225px;
    // padding: 10px 51px 20px 20px;
    #echart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<!--  -->
<template>
  <div class="volunteerStyle">
    <div class="changeBox" v-if="changeTeamShow">
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="el-dropdown-link">
          <span>{{teamName}}</span>
          <img src="../assets/downArrow.png" alt srcset />
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :disabled="teamName==item.name"
            :command="[item.type,item.name]"
            v-for="(item,index) in teamCate"
            :key="index"
          >{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="activityContent">
      <div class="activityTitle">
        <div style="position:absolute;left:26px">活动名称</div>
        <!-- <div style="position:absolute;left:187px">活动类型</div> -->
        <div style="position:absolute;left:230px">活动时间</div>
        <div style="position:absolute;right:185px">活动地点</div>
        <div style="position:absolute;right:28px">活动状态</div>
        <!-- <div style="position:absolute;left:12px">活动名称</div>
        <div style="position:absolute;left:187px">活动类型</div>
        <div style="position:absolute;left:270px">活动时间</div>
        <div style="position:absolute;right:155px">活动地点</div>
        <div style="position:absolute;right:0">活动状态</div>-->
      </div>
      <div @click="dacall">
        <vue-seamless-scroll
          :data="styleArray"
          v-if="styleArray.length"
          style="height:260px;overflow:hidden;position:relative;"
          :class-option="classOption"
        >
          <div class="box2" id="box2">
            <div v-for="(item,index) in styleArray" :key="index" :data-id="item.leaderId">
              <div class="activityPart" :class="{even : index % 2 === 1}" :data-id="item.leaderId">
                <div style="width:176px" :data-id="item.leaderId">{{item.name|ellipsis}}</div>
                <div style="width:87px" :data-id="item.leaderId">{{item.activityBeginTime}}</div>
                <div style="width:120px" :data-id="item.leaderId">{{item.activityLocation|ellipsis}}</div>
                <img
                  src="../assets/callImg.png"
                  alt
                  :data-id="item.leaderId"
                  v-if="item.status==4&&callRegion.includes($route.query.regionName)"
                  style="position:absolute;right:110px;"
                  srcset
                />
                <img
                  v-if="item.status==3"
                  src="../assets/volunteerActivity/zhaomu.png"
                  :data-id="item.leaderId"
                  alt
                />
                <img
                  v-if="item.status==4"
                  src="../assets/volunteerActivity/jinxing.png"
                  :data-id="item.leaderId"
                  alt
                />
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { activityCount } from '@/api/home'
export default {
  components: {
    vueSeamlessScroll
  },
  props: {
    cityId: {
      type: Number
    }
  },
  data() {
    return {
      classOption: {
        step: 0.1
      },
      swiper: {
        loop: true,
        speed: 800,
        autoplay: {
          delay: 300,
          stopOnLastSlide: true,
          disableOnInteraction: false
        }// 可选选项，自动滑动
      },
      styleArray: [],
      type: 1,
      teamCate: [{ name: '实践中心', type: 1 }, { name: '实践所', type: 2 }, { name: '团队', type: 3 }],
      changeTeamShow: false,
      teamName: '实践中心',
      regionId: 0,
      timer: null,
      callRegion: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  computed: {
    //计算属性
    // 轮播图 swiper对象
    swiper1() {
      return this.$refs.swiper1.swiper;
    }
  },
  mounted() {
    this.callRegion = window.dataPhone.callRegion
    this.timer = setInterval(() => {
      this.activityCount()
    }, 60000 * 5)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  filters: {
    ellipsis(value) {
      if (!value) return '暂无'
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    },
  },
  watch: {
    cityId: {
      handler(newVal) {
        if (newVal) {
          if (this.$route.query.regionId == newVal) {
            this.changeTeamShow = true
          } else {
            this.changeTeamShow = false
          }
          this.regionId = newVal
          this.activityCount()
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    // 打电话给别人
    dacall(e) {
      if (this.callRegion.includes(this.$route.query.regionName)) {
        let id = e.target.dataset.id;
        if (id) {
          console.log(id);
          // this.$store.commit('otheruserId', '456789');
          this.$store.commit('otheruserId', String(id));
        }
      }

    },
    handleCommand(command) {
      this.type = command[0]
      this.teamName = command[1]
      this.activityCount()
    },
    activityCount() {
      let data = { regionId: this.regionId, type: this.type, page: 1, rows: 100 }
      activityCount(data).then((res) => {
        console.log('活动', res.data);
        if (res.success) {
          // console.log('活动', res.data.items);
          this.styleArray = res.data.items;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.volunteerStyle {
  width: 100%;
  height: 100%;
  background-image: url("../assets/serviceStyle/bgActivity.png");
  background-size: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
  .changeBox {
    width: 110px;
    height: 26px;
    background-image: url("../assets/changeBox.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 14px;
    left: 492px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: PingFangHK;
    font-weight: 500;

    .el-dropdown-link {
      cursor: pointer;
      color: #ffffff;
    }
    .demonstration {
      display: block;
      color: #8492a6;
      font-size: 14px;
      margin-bottom: 20px;
    }
    img {
      width: 16px;
      height: 10px;
      margin-left: 8px;
    }
  }
  .activityContent {
    width: 100%;
    padding-top: 58px;
    display: flex;
    flex-direction: column;
    .activityTitle {
      width: 600px;
      height: 13px;
      font-size: 14px;
      font-family: PingFangHK;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      flex-direction: row;

      margin-bottom: 15px;
      margin-left: 12px;
      position: relative;
      .status {
        position: absolute;
      }
    }
    .activityPart {
      cursor: pointer;
      width: 603px;
      margin-left: 10px;
      height: 51px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      font-family: PingFangHK;
      font-weight: 500;
      color: #ffffff;
    }
    .even {
      background-image: url("../assets/volunteerActivity/smallBox.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
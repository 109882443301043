<!--  -->
<template>
  <div class="liveOn">
    <div class="liveBg">
      <div class="liveContent">
        <div
          v-for="(item,index) in liveList"
          :key="index"
          class="liveT"
          :style="{'background-image':indexChose==index?'url('+ require('../assets/live/qiehuan.png') +')':'url('+ require('../assets/live/xiaotu.png') +')'}"
          @click="getUrl(item,index)"
        >{{item.name|ellipsis}}</div>
      </div>
    </div>
    <div class="tvBg">
      <!-- <div muted class="videoBox" id="dplayer"></div> -->
      <iframe
        id="iframeContain"
        name="iframeContain"
        seamless
        scrolling="yes"
        :src="liveUrl"
        width="328px"
        height="235px"
        allowfullscreen
      >您当前的浏览器不支持页面上的功能，请升级您当前的浏览器版本或使用谷歌浏览器访问当前页面</iframe>
    </div>
  </div>
</template>

<script>
let Hls = require("hls.js");
import DPlayer from "dplayer";
import { lives } from '@/api/home'
export default {
  props: {
    cityId: {
      type: Number
    }
  },
  components: {
  },
  data() {
    return {
      dp: null,
      regionId: 0,
      liveList: [],
      liveCover: '',
      liveUrl: '',
      indexChose: 0
    }
  },
  watch: {
    cityId: {
      handler(newVal) {
        if (newVal) {
          this.regionId = newVal
          this.getLiveData()
        }
      },
      immediate: true,
      deep: true

    }

  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.timer = setInterval(() => {
      this.getLiveData()
    }, 60000 * 5)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getLiveData() {
      lives({ regionId: this.regionId }, { endTime: "", page: 0, startTime: "" }).then((res) => {
        if (res.success) {
          this.liveList = res.data.data
          this.liveCover = res.data.data[0].capture
          this.liveUrl = res.data.data[0].urlGroup.iframeUrl
          // this.liveList.push({ name: '2233' })
          // this.liveList.push({ name: '2233' })
          // this.liveList.push({ name: '2233' })
          // this.liveList.push({ name: '2233' })

        }
      })
    },
    getUrl(item, index) {
      this.indexChose = index
      this.liveCover = item.capture
      this.liveUrl = item.urlGroup.iframeUrl
      // this.videoPlay()
    },
    videoPlay() {
      this.dp = new DPlayer({
        container: document.getElementById("dplayer"),
        live: true,
        volume: 0,
        video: {
          autoplay: true,
          url: this.liveUrl,
          type: "customHls",
          customType: {
            customHls: function (video) {
              const hls = new Hls();
              hls.loadSource(video.src);
              hls.attachMedia(video);
            },
          },
        },
      });
      this.dp.play();
    }
  },
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.liveOn {
  width: 100%;
  height: 100%;
  background-image: url("../assets/live/livebg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 63px;
  box-sizing: border-box;
  .liveBg {
    width: 238px;
    height: 255px;
    background-image: url("../assets/live/live.png");
    background-size: 100%;
    background-repeat: no-repeat;

    .liveContent {
      margin-top: 42px;
      width: 238px;
      height: 213px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      .liveT {
        background-image: url("../assets/live/xiaotu.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 218px;
        padding: 7px 0;
        font-size: 13px;
        font-family: PingFangHK;
        font-weight: 500;
        color: #fffffe;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
  .tvBg {
    width: 350px;
    height: 255px;
    background-image: url("../assets/live/tv.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: 8px;
    position: relative;
    #iframeContain {
      position: absolute;
      top: 8px;
      left: 8px;
    }
    .videoBox {
      width: 328px;
      height: 235px;
      position: absolute;
      top: 11px;
      left: 11px;
    }
  }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userID: window.dataPhone.userID,
    otheruserId: '',
    EXPIRETIME: 604800
  },
  getters: {
  },
  mutations: {
    otheruserId(state, n) {
      state.otheruserId = n
    }
  },
  actions: {
  },
})

<template>
  <div class="map">
    <div class="map-main">
      <div class="zdfb">
        <div class="zd">
          <div class="name">
            <span ref="numberRoll0" :data-time="time" class="number-roll">0</span>
            <span style="font-size:13px">个</span>
          </div>
          <div style="color:#fff;font-size: 14px;">实践中心</div>
          <img src="../assets/position/home.png" alt srcset />
        </div>
        <div class="zd">
          <div class="name">
            <span ref="numberRoll1" :data-time="time" class="number-roll">0</span>
            <span style="font-size:13px">个</span>
          </div>
          <div style="color:#fff;font-size: 14px;">实践所</div>
          <img src="../assets/position/home.png" alt srcset />
        </div>
        <div class="zd">
          <div class="name">
            <span ref="numberRoll2" :data-time="time" class="number-roll">0</span>
            <span style="font-size:13px">个</span>
          </div>
          <div style="color:#fff;font-size: 14px;">实践站</div>
          <img src="../assets/position/home.png" alt srcset />
        </div>
        <div class="zd">
          <div class="name">
            <span ref="numberRoll3" :data-time="time" class="number-roll">0</span>
            <span style="font-size:13px">{{unit}}</span>
          </div>
          <div style="color:#fff;font-size: 14px;">志愿者</div>
          <img src="../assets/position/onePeople.png" alt srcset />
        </div>
        <div class="zd">
          <div class="name">
            <span ref="numberRoll4" :data-time="time" class="number-roll">0</span>
            <span style="font-size:13px">支</span>
          </div>
          <div style="color:#fff;font-size: 14px;">志愿队伍</div>
          <img src="../assets/position/people.png" alt srcset />
        </div>
        <div class="zd">
          <div class="name">
            <span ref="numberRoll5" :data-time="time" class="number-roll">0</span>
            <span style="font-size:13px">场</span>
          </div>
          <div style="color:#fff;font-size: 14px;">志愿活动</div>
          <img src="../assets/position/flag.png" alt srcset />
        </div>
      </div>
      <img class="bottomLine" src="../assets/bottomLine.png" alt srcset />
      <div class="mapBg">
        <div id="container"></div>
      </div>
    </div>

    <el-dialog
      title
      :visible.sync="dialogVisible"
      width="24%"
      height="900px"
      style="margin-top:-5vh"
      @close="closeDialog"
    >
      <iframe
        height="800px"
        width="100%"
        frameborder="0"
        :src="armyDetail"
        style="border-radius: 10px;"
      >
        <p>您的浏览器不支持 iframe 标签。</p>
      </iframe>
    </el-dialog>
    <!-- <div class="videoDiv">
      <el-dialog
        title="监控视频"
        :visible.sync="dialogVisibleTwo"
        width="40%"
        style="margin-top:-5vh"
        @close="closeDialog"
        center
      >
        <div muted class="videoBox" id="dplayer"></div>
      </el-dialog>
    </div>-->
  </div>
</template>

<script>
// let Hls = require("hls.js");
// import DPlayer from "dplayer";
import { organization, positionCount, underwayActivity, provinceRegionIdCount, regionUrl } from '@/api/home';
export default {
  props: {
    cityId: {
      type: Number,
    }
  },
  data() {
    return {
      // lnglats: [
      //   [108.909074, 34.254225],
      //   [108.910766, 34.254348],
      //   [108.910495, 34.253531],
      //   [108.909502, 34.253571],
      // ],
      dp: null,
      // 坐标点数据
      iconList: [],
      // 坐标点标识定时器
      timer: null,
      unit: '个',
      timerT: null,
      dialogVisible: false,
      dialogVisibleTwo: false,
      armyDetail: '',
      data: null,
      time: 100,
      num: 72000,
      regionId: 0,
      underwayActivity: [],
      appName: ['琅琊文明', '潜山文明', '桐城文明']
    }
  },
  watch: {

    cityId: {
      handler(newVal) {
        if (newVal) {
          this.regionId = newVal
          this.iconList = []
          clearInterval(this.timer);
          this.timer = null
          this.positionGet()
          if (this.$route.query.appName !== '长丰云') {
            this.getMapMes()

          } else {
            this.getUnderWay()
          }

          // this.getUnderWay()
          setTimeout(() => {
            if (JSON.stringify(this.iconList) == '[]') {
              this.regionId = Number(this.$route.query.regionId)
              if (this.$route.query.appName !== '长丰云') {
                this.getMapMes()
              } else {
                this.getUnderWay()
              }
            }
          }, 100);

        }
      },
      immediate: true,
      deep: true

    }

  },

  created() {

  },

  mounted() {
    var _this = this
    _this.timerT = setInterval(function () {
      _this.iconList = []
      if (this.$route.query.appName !== '长丰云') {
        this.getMapMes()
      } else {
        this.getUnderWay()
      }
      _this.positionGet()
    }, 300000)
    window.dacall = this.dacall
  },
  methods: {

    numberRoll(ele, num) {
      let _this = this;
      let step = (num * 100) / (_this.time * 100);
      let current = 0;
      let start = 0;
      let t = setInterval(function () {
        start += step;
        if (start > num) {
          clearInterval(t);
          start = num;
          t = null;
        }
        if (current === start) {
          return;
        }
        current = start;
        ele.innerHTML = current
          .toFixed(0)
          .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, "$1,");
      }, 10);
    },
    positionGet() {
      positionCount({ regionId: this.regionId }).then((res) => {
        console.log('数量', res);
        if (res.success) {
          if (res.data.volunteerCount > 99999) {
            res.data.volunteerCount = (res.data.volunteerCount / 10000).toFixed(1)
            this.unit = '万'
          }
          this.numberRoll(this.$refs.numberRoll0, res.data.center);
          this.numberRoll(this.$refs.numberRoll1, res.data.place);
          this.numberRoll(this.$refs.numberRoll2, res.data.station);
          this.numberRoll(this.$refs.numberRoll3, res.data.volunteerCount);
          this.numberRoll(this.$refs.numberRoll4, res.data.volunteerRank);
          this.numberRoll(this.$refs.numberRoll5, res.data.volunteerActivity);

        }
      })
    },
    closeDialog() {
      this.armyDetail = ''
    },
    // 获取数据
    getMapMes() {
      provinceRegionIdCount({ regionId: this.regionId }).then((res) => {
        if (res.success) {
          // 处理数据
          if (res.data.length > 0) {
            let temGis = res.data
            for (let i = 0; i < temGis.length; i++) {
              let temGisList = {}
              let temarry = [];
              temarry.push(temGis[i].lng)
              temarry.push(temGis[i].lat)
              temGisList.title = temGis[i].simpleName
              temGisList.position = temarry
              temGisList.id = temGis[i].id
              this.iconList.push(temGisList)
            }
            this.getMes()
          } else {
            this.getMes()
          }

        } else {
          this.$message(res.message)
        }
      })
    },
    getRegionUrl(e) {
      console.log(e);
      regionUrl({ regionId: e }, { regionId: e }).then((res) => {
        if (res.success) {

          // this.videoPlay(res.data.url)
        }
        console.log(res);
      })
    },
    // videoPlay(val) {
    //   console.log(val);
    //   this.dp = new DPlayer({
    //     container: document.getElementById("dplayer"),
    //     // live: true,
    //     volume: 0,
    //     video: {
    //       // autoplay: true,
    //       url: 'https://live.fst1994.net/hls/lixinxw/index.m3u8',
    //       type: "customHls",
    //       customType: {
    //         customHls: function (video) {
    //           const hls = new Hls();
    //           hls.loadSource(video.src);
    //           hls.attachMedia(video);
    //         },
    //       },
    //     },
    //   });
    //   this.dp.play();
    // },
    getMes() {//获取琅琊地图
      var map = new window.AMap.Map("container", {
        resizeEnable: true,
        zoom: 12,//级别
        center: [117.25, 31.83]
      });//初始化地图
      //信息窗口实例
      var infoWindow = new window.AMap.InfoWindow({ offset: new window.AMap.Pixel(0, -30) });

      //遍历生成多个标记点
      if (this.iconList.length > 0) {
        // let tem = []
        for (var i = 0; i < this.iconList.length; i++) {
          let marker = new window.AMap.Marker({
            position: this.iconList[i].position,//不同标记点的经纬度
            map: map,
            color: '#000',
            icon: new window.AMap.Icon({
              size: new window.AMap.Size(37, 37),
              image: require("../assets/mapLogo.png"),
              imageSize: new window.AMap.Size(37, 37)
            }),
          });
          marker.content = this.iconList[i];
          // marker.on('click', markerClick);
          // marker.emit('click', { target: marker });//默认初始化不出现信息窗体,打开初始化就出现信息窗体
        }
      }
      // let that = this
      var _this = this
      // function markerClick(e) {
      //   console.log(e);
      //   _this.getRegionUrl(e.target.content.id)

      //   _this.dialogVisibleTwo = true
      // }
      let current = this.iconList.length;
      let start = 0;
      if (current > 1) {
        // 标点文本注释跳跃
        this.$nextTick(() => {
          _this.timer = setInterval(function () {
            if (start >= current) {
              start = 0
            }
            map.setCenter([_this.iconList[start].position[0], _this.iconList[start].position[1]])
            infoWindow.setContent(_this.iconList[start].title);
            infoWindow.open(map, _this.iconList[start].position);
            start++
          }, 3500);
        })
      } else if (current === 1) {
        // 只有一个点的时候
        infoWindow.setContent(_this.iconList[0].title);
        infoWindow.open(map, _this.iconList[0].position);
      }
      // 

      map.setFitView();
      map.setZoom(10);
    },
    getUnderWay() {
      underwayActivity({ regionId: this.regionId }).then((res) => {
        if (res.success) {
          if (res.data.length && res.data.length > 0) {
            let temGis = res.data
            for (let i = 0; i < temGis.length; i++) {
              let temGisList = {}
              let temarry = [];
              temarry.push(temGis[i].longitude)
              temarry.push(temGis[i].latitude)
              temGisList.title = temGis[i].name
              temGisList.time = temGis[i].activityBeginTime
              temGisList.summary = temGis[i].summary
              temGisList.activityLocation = temGis[i].activityLocation
              temGisList.leaderName = temGis[i].leaderName
              temGisList.leaderPhone = temGis[i].leaderPhone
              temGisList.id = temGis[i].regionId
              temGisList.leaderId = temGis[i].leaderId
              temGisList.position = temarry
              this.iconList.push(temGisList)

            }
            this.carGPSIP()
          } else {
            this.carGPSIP()
          }
        }
      })
    },


    // 获取数据
    getMap() {
      organization({ regionId: this.regionId, type: 1 }).then((res) => {
        if (res.success) {
          console.log('地图', res.data)
          // 处理数据
          if (res.data.length > 0) {
            let temGis = res.data
            for (let i = 0; i < temGis.length; i++) {
              let temGisList = {}
              let temarry = [];
              temarry.push(temGis[i].longitude)
              temarry.push(temGis[i].latitude)
              temGisList.title = temGis[i].name
              temGisList.time = temGis[i].activityBeginTime
              temGisList.summary = temGis[i].summary
              temGisList.activityLocation = temGis[i].activityLocation
              temGisList.leaderName = temGis[i].leaderName
              temGisList.leaderPhone = temGis[i].leaderPhone
              temGisList.id = temGis[i].regionId
              temGisList.leaderId = temGis[i].leaderId
              temGisList.position = temarry
              this.iconList.push(temGisList)

            }
            this.carGPSIP()
          } else {
            this.carGPSIP()
          }

        } else {
          this.$message(res.message)
        }
      })
    },
    // 打电话给别人
    dacall(e) {
      if (this.$route.query.regionName == '长丰县') {
        let id = e
        if (id) {
          // this.$store.commit('otheruserId', '456789');
          this.$store.commit('otheruserId', String(id));
        }
      }
    },
    carGPSIP() {
      // console.log(AMap);
      var map = new window.AMap.Map("container", {
        resizeEnable: true,
        // zoom: 15,//级别
        zooms: [10, 15],
        center: [117.577, 30.175036]
      });//初始化地图
      //信息窗口实例
      var infoWindow = new window.AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        // content: getContent(),
        offset: new window.AMap.Pixel(10, -30)
      });

      function ellipsisWord(val, length) {
        if (!val) return '暂无'
        if (val.length > length) {
          return val.slice(0, length) + '...'
        }
        return val
      }
      //处理窗体内容
      function getContent(val) {
        val.title = ellipsisWord(val.title, 22)
        val.activityLocation = ellipsisWord(val.activityLocation, 22)
        val.summary = ellipsisWord(val.summary, 22)
        val.leaderName = ellipsisWord(val.leaderName, 10)
        const reg = /^(.{3}).*(.{4})$/;
        val.leaderPhone = val.leaderPhone ? val.leaderPhone.replace(reg, '$1****$2') : ''
        var content = `<div class="custom-info" style="word-break:break-all;width: 247px;height: 203px;font-size:14px;background-image: url('https://changfeng.fst1994.net/1.png');background-zize:100%;display:flex;flex-direction:column;padding:13px 0 0 15px;box-sizing:border-box;">
                <div style="width: 209px;"><span style="color:#3FC6ED;">活动名称：</span>${val.title}</div>
                <div style="width: 209px;margin-top:5px;"><span style="color:#3FC6ED;">活动时间：</span>${val.time}</div>
                <div style="width: 209px;margin-top:5px;"><span style="color:#3FC6ED;">活动地点：</span>${val.activityLocation}</div>
                <div style="width: 209px;margin-top:5px;"><span style="color:#3FC6ED;">活动简介：</span>${val.summary}</div>
                <div style="width: 185px;margin-top:5px;" onclick="dacall(${val.leaderId})"><span style="color:#3FC6ED;">联系人：</span>${val.leaderName} ${val.leaderPhone}<img style="position:absolute;right:10px;bottom:35px;" src="https://changfeng.fst1994.net/2.png"></img></div>
            </div >`
        return content
      }

      //遍历生成多个标记点
      if (this.iconList.length > 0) {
        // let tem = []
        for (var i = 0; i < this.iconList.length; i++) {
          let marker = new window.AMap.Marker({
            position: this.iconList[i].position,//不同标记点的经纬度
            map: map,
            color: '#000',
            icon: new window.AMap.Icon({
              size: new window.AMap.Size(37, 37),
              image: require("../assets/mapLogo.png"),
              imageSize: new window.AMap.Size(37, 37)
            }),//设置了没改变
          });

          marker.content = this.iconList[i];
          marker.on('mouseover', infoOpen);
          //注释后打开地图时默认关闭信息窗体
          //marker.emit('mouseover', {target: marker});
          marker.on('mouseout', infoClose);
          marker.on('click', markerClick);
          // marker.emit('click', { target: marker });//默认初始化不出现信息窗体,打开初始化就出现信息窗体
          // tem.push(marker)
          // console.log({target: marker});
          // console.log(marker);
        }
        // console.log(tem);
      }
      function infoClose(e) {
        map.setCenter([e.target.content.position[0], e.target.content.position[1]])
        infoWindow.close(map, e.target.content.position);
      }
      function infoOpen(e) {
        map.setCenter([e.target.content.position[0], e.target.content.position[1]])
        let content = getContent(e.target.content)
        if (_this.$route.query.regionName !== '长丰县') {
          content = content.replace(/<img/gi, '<img style="display:none;');
          console.log(content);
        }
        infoWindow.setContent(content);
        infoWindow.open(map, e.target.content.position);
      }
      var _this = this
      function markerClick(e) {
        console.log('22222', e);
        _this.iconList.forEach(item => {
          // console.log(item);
          if (item.title == e.target.content.title) {
            _this.armyDetail = process.env.VUE_APP_OTHERLINK + item.id
          }
        })
        _this.dialogVisible = true
      }
      map.setFitView();
      map.setZoom(7);
    },

  },



  beforeDestroy() {
    clearInterval(this.timer);// 清除定时器
    this.timer = null;
    clearInterval(this.timerT);
  },
};
</script>

<style lang="less" scoped>
.map {
  width: 100%;
  height: 100%;
  // border: 1px solid #00ffff;
  background-image: url("../assets/position/positionBg.png");
  background-size: 100% 100%;
  background-repeat: round;
  .map-main {
    width: 100%;
    height: 100%;
    padding-top: 46px;
    position: relative;
    .zdfb {
      width: 100%;
      height: 114px;
      padding: 0 25px;
      display: flex;
      flex-direction: row;

      .zd {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        width: 95px;
        align-items: center;
        .name {
          font-family: "numText";
          font-size: 24px;
          font-weight: bold;
          color: #ffe4b0;
        }
        img {
          margin-top: -45px;
          width: 95px;
        }
      }
    }
    .bottomLine {
      position: absolute;
      top: 116px;
      left: 10px;
    }
    .topic {
      width: 300px;
      height: 46px;
      background: linear-gradient(
        to right,
        rgba(0, 170, 255, 0.3),
        transparent
      );
      display: flex;
      p {
        font-size: 22px;
        font-weight: bold;
        line-height: 6px;
        letter-spacing: 2px;
        text-align: left;
        margin-left: 10px;
      }
      img {
        width: 8px;
        height: 35px;
        margin-top: 5px;
        margin-left: 1px;
      }
    }
    .mapBg {
      width: 596px;
      height: 448px;
      background: url("../assets/position/mapBg.png");
      background-size: 100%;
      position: relative;
      left: 19px;
      top: 11px;
    }
    #container {
      width: 586px;
      height: 442px;
      left: 3px;
      top: 3px;
      // background: url("../assets/position/positionBg.png");
      // margin-left: 653px;
      // margin-top: 122px;
      position: absolute;
    }
  }
}
.videoDiv {
  /deep/ .el-dialog__body {
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoBox {
    width: 95%;
    height: 450px;
  }
}

/deep/ .amap-info-content {
  color: #000;
}
</style>

<template>
  <div style="width:100%">
    <div class="bg">
      <div class="headT">
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="el-dropdown-link choseP">
            <img src="../assets/position.png" />
            {{cityName}}
            <span slot="reference">[切换区域]</span>
          </div>
          <el-dropdown-menu slot="dropdown" class="menu">
            <el-dropdown-item
              :disabled="item.simpleName==cityName"
              v-for="(item,index) in cityArray"
              :command="[item.id,item.simpleName]"
              :key="index"
            >{{item.simpleName}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="choseP">
          <img src="../assets/position.png" />
          {{cityName}}
          <el-popover trigger="click">
            <el-cascader-panel
              ref="cascader"
              @change="getCity"
              :options="cityArray"
              :props="defaultParams"
            ></el-cascader-panel>
            <span slot="reference">[切换区域]</span>
          </el-popover>
        </div>-->
        <div class="title">{{$route.query.regionName}}新时代文明实践指挥调度中心</div>
        <div class="timeNow">{{newTime}}</div>
      </div>
      <div class="mainContent">
        <div class="row">
          <div class="volunteerT">
            <!-- 志愿服务总时长 -->
            <volunteerTime :cityId="cityId"></volunteerTime>
          </div>
          <div class="volunteerN">
            <!-- 志愿者统计数量 -->
            <statistics-number :cityId="cityId"></statistics-number>
          </div>
          <div class="volunteerN">
            <!-- 活动开展统计 -->
            <activity-statistics :cityId="cityId"></activity-statistics>
          </div>
        </div>
        <div class="row">
          <div class="positionGis">
            <!-- GIS阵地分布 -->
            <map-gis :cityId="cityId"></map-gis>
          </div>
          <div class="qunzhongdiandan">
            <!-- 群众点单 -->
            <massOrder :cityId="cityId"></massOrder>
          </div>
        </div>
        <div class="row">
          <div class="zhiyuanfuwu">
            <!-- 志愿者服务风采 -->
            <volunteerStyle :cityId="cityId"></volunteerStyle>
          </div>
          <div class="zhiyuanActivity">
            <!-- 志愿者服务活动 -->
            <volunteerActivity :cityId="cityId"></volunteerActivity>
          </div>
          <div class="zhiyuanxianchang">
            <!-- 直播 -->
            <liveOn :cityId="cityId"></liveOn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import volunteerTime from "../components/volunteerTime";
import massOrder from "../components/massOrder";
import volunteerStyle from '../components/volunteerStyle';
import volunteerActivity from '../components/volunteerActivity';
import { provinceRegionIdCount } from '@/api/home';
import statisticsNumber from '../components/statisticsNumber.vue';
import activityStatistics from "../components/activityStatistics.vue";
import mapGis from "../components/mapGis.vue"
import liveOn from "../components/liveOn.vue";
export default {
  components: {
    volunteerTime,
    // positonVolunteer,
    volunteerStyle,
    volunteerActivity,
    statisticsNumber,
    activityStatistics,
    mapGis,
    massOrder,
    liveOn
  },
  data() {
    return {
      cityName: '长丰县',
      cityArray: [],
      defaultParams: {
        label: "name",
        value: "regionId",
        children: "children",
        checkStrictly: true,
        disabled: 'disable'
      },
      cityId: 0,
      newTime: '',
      week: '',
      nowTimer: null
    };
  },
  mounted() {
    this.cityId = this.$route.query.otherRegionId ? Number(this.$route.query.otherRegionId) : Number(this.$route.query.regionId)
    this.cityName = this.$route.query.otherRegionName ? this.$route.query.otherRegionName : this.$route.query.regionName
    this.nowTimer = setInterval(() => {
      this.getNowTime()
    }, 1000)

    this.getRegion()
  },
  beforeDestroy() {
    if (this.getNowTime) {
      clearInterval(this.nowTimer); // 在Vue实例销毁前，清除时间定时器
    }
  },
  methods: {

    getRegion() {
      provinceRegionIdCount({ regionId: this.cityId }).then((res) => {
        if (res.success) {
          this.cityArray = res.data
          this.cityArray.unshift({ simpleName: this.$route.query.regionName, id: this.$route.query.regionId })
        }
      })
    },
    handleCommand(command) {
      this.cityId = Number(command[0])
      this.cityName = command[1]
      this.getRegion()
    },
    getNowTime() {
      let nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      }
      let newmonth = date.month >= 10 ? date.month : "0" + date.month;
      let newday = date.date >= 10 ? date.date : "0" + date.date;
      let newminutes = date.minutes < 10 ? "0" + date.minutes : date.minutes;
      let newseconds = date.seconds < 10 ? "0" + date.seconds : date.seconds;
      let wk = new Date().getDay()
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      this.week = weeks[wk]
      this.newTime = date.year + "." + newmonth + "." + newday + "\xa0\xa0\xa0" + this.week + "\xa0\xa0\xa0" + date.hours + ":" + newminutes + ":" + newseconds;


    },

    getCity(val) {
      this.cityId = val[val.length - 1]
      let nodesInfo = this.$refs['cascader'].getCheckedNodes()
      this.cityName = nodesInfo[0].label;
    }
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/font/font.css");
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.headT {
  padding: 18px 18px 0px 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fffafe;
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.menu {
  height: 200px;
  width: 150px;
  overflow: auto;
  text-align: center;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.choseP {
  font-size: 23px;
}
.choseP span {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #6e9fcd;
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.choseP img {
  width: 19px;
  height: 25px;
  margin: 3px 4px 0 0;
  float: left;
}
.title {
  font-family: "headText";
  font-size: 32px;
  font-weight: 800;
  color: #fffafe;
  text-shadow: 0px 6px 5px rgba(21, 12, 31, 0.78);
  position: absolute;
  left: 672px;
  letter-spacing: 5px;
}
.timeNow {
  font-size: 20px;
  font-family: DINOT;
}
.bg {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  // text-align: center;
  background-color: #141a48;
  background: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.mainContent {
  padding: 0 18px 19px 18px;
  margin-top: 42px;
  width: 100%;
  height: 100%;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}
.row {
  width: 33%;
  display: flex;
  flex-direction: column;
}
.volunteerT {
  width: 623px;
  height: 419px;

  margin-bottom: 8px;
  overflow: hidden;
}
.volunteerN {
  width: 623px;
  height: 271px;
  margin-bottom: 8px;
  overflow: hidden;
}
.position {
  width: 623px;
  height: 484px;
  margin-bottom: 8px;
  overflow: hidden;
}
.positionGis {
  width: 623px;
  height: 631px;
  // border: 1px solid #00ffff;
  map-gis {
    width: 100%;
    height: 100%;
  }
}
.qunzhongdiandan {
  width: 623px;
  height: 338px;
  margin-top: 8px;
  // border: 1px solid #00ffff;
}
.zhiyuanfuwu {
  width: 623px;
  height: 262px;
  // border: 1px solid #00ffff;
}
.zhiyuanActivity {
  width: 623px;
  height: 361px;
  margin-top: 8px;
  // border: 1px solid #00ffff;
}
.zhiyuanxianchang {
  width: 623px;
  height: 338px;
  margin-top: 8px;
}
</style>

<!--  -->
<template>
  <div class="volunteerStyle">
    <div class="changeBox" v-if="changeTeamShow">
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="el-dropdown-link">
          <span>{{teamName}}</span>
          <img src="../assets/downArrow.png" alt srcset />
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :disabled="teamName==item.name"
            :command="[item.type,item.name]"
            v-for="(item,index) in teamCate"
            :key="index"
          >{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="mainStyle" @mouseenter="autoPlayStop" @mouseleave="autoPlayStart">
      <swiper :options="swiper" ref="swiperStyle" v-if="swiperSlides.length">
        <swiper-slide class="styleContent" v-for="(slide, index) in swiperSlides" :key="index">
          <div class="picText" v-for="(ontent,indexli) in slide" :key="indexli">
            <div class="bg">
              <img :src="ontent.imgUrl" v-if="ontent.imgUrl" alt srcset />
            </div>

            <!-- <img v-else src="../assets/serviceStyle/noPhoto.png" alt srcset /> -->
            <div class="topTime">{{ontent.createTime.substring(0,10)}}</div>
            <div class="bottomT">
              <span v-if="ontent.mainContributionTitle.length<26">{{ontent.mainContributionTitle}}</span>
              <el-tooltip
                v-else
                :content="ontent.mainContributionTitle"
                placement="bottom"
                effect="dark"
                popper-class="atooltip"
                style="cursor:pointer"
              >
                <span>{{ontent.mainContributionTitle|ellipsis}}</span>
              </el-tooltip>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper/src'
import { activityShow } from '@/api/home';
export default {

  components: {
    swiper,
    swiperSlide
  },
  props: {
    cityId: {
      type: Number
    }
  },
  data() {
    return {
      errorImage: 'this.src="' + require('@/assets/serviceStyle/noPhoto.png') + '"',
      swiper: {
        loop: true,
        speed: 800,

        autoplay: {
          delay: 3000,
          stopOnLastSlide: true,
          disableOnInteraction: false
        }// 可选选项，自动滑动
      },
      type: 1,
      teamCate: [{ name: '实践中心', type: 1 }, { name: '实践所', type: 2 }, { name: '团队', type: 3 }],
      changeTeamShow: false,
      teamName: '实践中心',
      swiperSlides: [],
      regionId: 0,
      timer: null
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 26) {
        return value.slice(0, 25) + '...'
      }
      return value
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  watch: {
    cityId: {
      handler(newVal) {
        if (newVal) {
          if (this.$route.query.regionId == newVal) {
            this.changeTeamShow = true
          } else {
            this.changeTeamShow = false
          }
          this.regionId = newVal
          this.swiperSlides = [];
          this.activityShow()
        }
      },
      immediate: true,
      deep: true

    }

  },
  computed: {
    //计算属性

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.timer = setInterval(() => {
      this.activityShow()
    }, 60000 * 5)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    handleCommand(command) {
      this.type = command[0]
      this.teamName = command[1]

      this.activityShow()
    },
    setDefaultImage(e) {
      // console.log('iojwefijoig');
      e.target.src = require('@/assets/serviceStyle/noPhoto.png')
    },
    // 鼠标放上去停止
    autoPlayStop() {
      this.$refs.swiperStyle.swiper.autoplay.stop();
    },
    // 鼠标离开开始运动
    autoPlayStart() {
      this.$refs.swiperStyle.swiper.autoplay.start();
    },
    activityShow() {
      let data = { type: this.type, page: 1, rows: 33, regionId: this.regionId }
      activityShow(data).then((res) => {
        console.log(res);
        if (res.success) {
          let conAll = []
          let data = res.data
          for (var i = 0; i < data.length / 3; i++) {
            if (data[i].contributionCover !== '[]') {
              data[i].imgUrl = JSON.parse(data[i].contributionCover)[0]
            } else {
              data[i].imgUrl = null
            }
            conAll.push(data.slice(i * 3, i * 3 + 3));
          }
          this.swiperSlides = conAll;
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.volunteerStyle {
  width: 100%;
  height: 100%;
  background-image: url("../assets/serviceStyle/bgStyle.png");
  background-size: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
  .changeBox {
    width: 110px;
    height: 26px;
    background-image: url("../assets/changeBox.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 14px;
    left: 492px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: PingFangHK;
    font-weight: 500;

    .el-dropdown-link {
      cursor: pointer;
      color: #ffffff;
    }
    .demonstration {
      display: block;
      color: #8492a6;
      font-size: 14px;
      margin-bottom: 20px;
    }
    img {
      width: 16px;
      height: 10px;
      margin-left: 8px;
    }
  }
  .mainStyle {
    width: 100%;
    box-sizing: border-box;
    padding-top: 66px;
    padding-left: 18px;
    .styleContent {
      display: flex;

      align-items: center;
      .picText {
        margin-right: 10px;
        width: 188px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .bg {
          width: 188px;
          height: 126px;
          border-radius: 4px;
          background-image: url("../assets/serviceStyle/noPhoto.png");
          background-size: 100% 100%;
          img {
            width: 188px;
            height: 126px;
            border-radius: 4px;
          }
        }

        .topTime {
          border-top-left-radius: 4px;
          width: 92px;
          height: 26px;
          background: #f67119;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
        }
        .bottomT {
          height: 45px;
          font-size: 14px;
          font-family: PingFangHK;
          font-weight: 500;
          color: #ffffff;
          line-height: 22px;
          margin-top: 13px;
        }
      }
    }
  }
}
</style>
import axios from "axios";
import { Notification } from "element-ui";
// 清除所有缓存数据
// import { tologin } from "@/utils/toLogin";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 240000, // request timeout
});

// 请求头
service.interceptors.request.use(
  (config) => {
    // var timestamp = new Date().getTime();
    // var expireTime = Number(window.localStorage.expireTime); // 超时的时间
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    // if (window.localStorage.token) {
    //   if (timestamp > expireTime) {
    //     config.headers["Authorization"] = window.localStorage.refreshToken;
    //   } else {
    if (localStorage.getItem("zhiHuiXinZhouwebToken")) {
      config.headers["Authorization"] = JSON.parse(
        localStorage.getItem("zhiHuiXinZhouwebToken")
      );
    }
    //   }
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 请求响应拦截
service.interceptors.response.use(
  (response) => {
    // 接口请求成功
    return response.data;
  },
  (error) => {
    // 接口请求错误
    let code = 0;
    try {
      code = error.response.status;
    } catch (e) {
      if (error.toString().indexOf("Error: timeout") !== -1) {
        Notification.error({
          showClose: true,
          title: "网络请求超时",
          duration: 5000,
        });
        return Promise.reject(error);
      }
    }
    if (code) {
      if (code === 403) {
        localStorage.removeItem("zhiHuiXinZhouwebToken");
        // console.log("我的主应用");
        // Vue.prototype.setGlobalState({
        //   linkstatus: 200,
        // });
        // tologin(); // 跳转登录页面或者其他页面
      }
      // else {
      //   const errorMsg = error.response.data.message;
      //   if (errorMsg !== undefined) {
      //     Notification.error({
      //       title: errorMsg,
      //       duration: 5000,
      //     });
      //   } else {
      //     Notification.error({
      //       title: "不好意思，出小差了",
      //       duration: 5000,
      //     });
      //   }
      // }
    }
    // else {
    //   Notification.error({
    //     title: "不好意思，出小差了",
    //     duration: 5000,
    //   });
    // }
    return Promise.reject(error);
  }
);

export default service;

<template>
  <div>
    <div class="volunteer" style="color:white">
      <!-- 志愿服务总时长 -->
      <div style="width:100%;height:100%;padding: 9px 13px 9px;display:flex;">
        <div class="volun-left">
          <div class="total-Time">
            <p class="topic">志愿服务总时长</p>
            <div class="total-List">
              <ul>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num1 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num2 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num3 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num4 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num5 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num6 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num7 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num8 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num9 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num10 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num11 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
                <li>
                  <div class="Template" :style="{ top:( -1 * totalTime.num12 * 37) +'px'}">
                    <p>0</p>
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                  </div>
                </li>
              </ul>
              <span>小时</span>
            </div>
          </div>
          <div class="ranking-List">
            <div class="list-Top">排行榜单</div>
            <div class="list-Three">
              <div
                class="three-Top"
                v-for="(item,i) in list"
                :key="i"
                :class="i === 1 ? 'active' :''"
              >
                <h3
                  class="hexagon"
                  :class="i === 0 ? '':(i===1?'middle':'three')"
                >{{i===0?'2':(i===1?'1':'3')}}</h3>
                <div class="Number">
                  <h4 :title="item.name">{{item.name}}</h4>
                  <p :title="item.time">{{item.time}}</p>
                </div>
              </div>
            </div>
            <div class="list-List">
              <div class="list-Main" v-if="maniList.length>0">
                <div class="mainlist" v-for="(item,manIndex) in maniList" :key="manIndex">
                  <div class="cityList" :title="item.name +'--' +item.time">
                    <span>{{manIndex+4}}</span>
                    <p>{{item.name}} -- {{item.time}}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="list-Main" v-if="maniListTwo.length>0">
                <div  class="mainlist" v-for="(item,TwoIndex) in maniListTwo" :key="TwoIndex">
                  <div class="cityList" :title="item.name +'--' +item.time">
                    <span>{{TwoIndex+11}}</span>
                    {{item.name}} -- {{item.time}}
                  </div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
        <div class="volun-right">
          <p class="right-top">签到提醒</p>
          <vue-seamless-scroll :data="remind" :class-option="classOption" class="warp right-list">
            <ul class="item" v-if="remind.length>0">
              <li v-for="(itemR, index) in remind" :key="index">
                <div class="item-top">
                  <p class="left-remind" :title=" itemR.memberName + '-' +itemR.name">
                    <span></span>
                    {{itemR.memberName}} - {{itemR.name}}
                  </p>
                  <p class="right-remind">时长+{{itemR.serverTime}}</p>
                </div>
                <div class="item-bottom" :title="itemR.regionName">
                  <p>{{itemR.regionName}}</p>
                </div>
              </li>
            </ul>
            <ul v-else style="display:flex;justify-content: center;padding-top:80px">
              <img style="width:80%;height:auto;" src="../assets/volunteerTime/empty.png" alt />
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import vueSeamlessScroll from 'vue-seamless-scroll'
import { volunteerTime } from '@/api/home';
export default {
  components: {
    vueSeamlessScroll
  },
  props: {
    cityId: {
      type: Number
    }
  },
  data() {
    return {
      // 排行榜单前三
      list: [{}, {}, {}],
      // 排行榜单前三之后的数据
      maniList: [],
      // maniListTwo:[],
      // 签到提醒数组
      remind: [],
      totalTime: {
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0,
        num6: 0,
        num7: 0,
        num8: 0,
        num9: 0,
        num10: 0,
        num11: 0,
        num12: 0,
      },
      // 签到提醒滚动速度
      classOption: {
        step: 0.7
      },
      timer: null
      // id:null
    }
  },
  watch: {
    cityId: {
      handler(newVal) {
        if (newVal) {
          this.maniList = []
          this.list = [{}, {}, {}]
          this.remind = []
          this.getList()
        }
      },
      immediate: true,
      deep: true

    }

  },
  created() {
    // this.getList()
  },
  mounted() {
    var _this = this
    _this.timer = setInterval(function () {
      this.maniList = []
      this.list = [{}, {}, {}]
      this.remind = []
      _this.getList()
    }, 300000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getList() {
      // 调用接口
      volunteerTime({ regionId: this.cityId, page: 1, rows: 20, pageOne: 1, rowsOne: 10 }).then((res) => {
        if (res.data.totalServiceTime == null) {
          Object.keys(this.totalTime).forEach(key => { this.totalTime[key] = 0 })
        } else {
          let temTatalTime = res.data.totalServiceTime.split('');
          let tem = temTatalTime.length;
          // console.log(temTatalTime,tem);
          // 服务总时长
          // console.log(temTatalTime[tem-12]);
          this.totalTime.num1 = temTatalTime[tem - 12] !== undefined ? temTatalTime[tem - 12] : 0;
          this.totalTime.num2 = temTatalTime[tem - 11] !== undefined ? temTatalTime[tem - 11] : 0;
          this.totalTime.num3 = temTatalTime[tem - 10] !== undefined ? temTatalTime[tem - 10] : 0;
          this.totalTime.num4 = temTatalTime[tem - 9] !== undefined ? temTatalTime[tem - 9] : 0;
          this.totalTime.num5 = temTatalTime[tem - 8] !== undefined ? temTatalTime[tem - 8] : 0;
          this.totalTime.num6 = temTatalTime[tem - 7] !== undefined ? temTatalTime[tem - 7] : 0;
          this.totalTime.num7 = temTatalTime[tem - 6] !== undefined ? temTatalTime[tem - 6] : 0;
          this.totalTime.num8 = temTatalTime[tem - 5] !== undefined ? temTatalTime[tem - 5] : 0;
          this.totalTime.num9 = temTatalTime[tem - 4] !== undefined ? temTatalTime[tem - 4] : 0;
          this.totalTime.num10 = temTatalTime[tem - 3] !== undefined ? temTatalTime[tem - 3] : 0;
          this.totalTime.num11 = temTatalTime[tem - 2] !== undefined ? temTatalTime[tem - 2] : 0;
          this.totalTime.num12 = temTatalTime[tem - 1] !== undefined ? temTatalTime[tem - 1] : 0;
        }

        if (res.data.regionIdTime.items.length > 0) {
          // 排行榜单前三个处理数据
          let temList = res.data.regionIdTime.items;

          if (temList.length == 1) {
            let temTopList = temList.slice(0);
            this.list[1] = temTopList[0];
          } else if (temList.length == 2) {
            let temTopList = temList.slice(0);
            this.list[1] = temTopList[0]; this.list[0] = temTopList[1];
          } else {
            let temTopList = temList.slice(0, 3);
            this.list[0] = temTopList[1]; this.list[1] = temTopList[0]; this.list[2] = temTopList[2];
          }
          // 列表数据处理
          // console.log(temList,temTopList);
          // console.log(this.list);
          let temBottomList = temList.slice(3)
          if (temBottomList.length > 14) {
            this.maniList = temBottomList.slice(0, 14)
          } else {
            this.maniList = temBottomList.slice(0)
          }
        } else {
          this.maniList = []
          this.list = [{}, {}, {}]
        }
        // console.log(res.data.memberIdTime.items,'签到提醒数据');
        if (res.data.memberIdTime.items.length > 0) {
          // 签到提醒数据
          let signList = res.data.memberIdTime.items;
          this.remind = signList;
          // console.log(this.remind )
        } else {
          this.remind = []
        }

      })
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
  text-align: center;
}
li {
  list-style: none;
}
.bg {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #141a48;
  background: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.volunteer {
  background-image: url("../assets/position/bigBg.png");
  background-repeat: round;
  width: 623px;
  height: 419px;
  overflow: hidden;
  // padding: 19px 13px 15px;
  display: flex;
  .volun-left {
    width: 339px;
    height: 401px;
    background-image: url("../assets/volunteerTime/left.png");
    background-repeat: round;
    // background-size: 100%;

    .total-Time {
      width: 100%;
      // height: 61px;
      margin-top: 10px;
      // border: 1px solid green;
      .topic {
        text-align: left;
        font-size: 17px;
        letter-spacing: 2px;
        font-weight: bold;
        color: #fff;
        margin-left: 16px;
      }
      .total-List {
        margin-left: 16px;
        margin-top: 7px;
        width: 100%;
        height: 37px;
        display: flex;
        ul {
          display: flex;
        }
        li {
          background-image: url("../assets/volunteerTime/topMin.png");
          background-repeat: round;
          height: 37px;
          // width: 29px;
          width: 23px;
          margin-right: 0px;
          font-size: 29px;
          font-weight: bold;
          color: #3bf8ff;
          line-height: 38px;
          position: relative;
          overflow: hidden;
        }
        .Template {
          width: 100%;
          height: 370px;
          position: absolute;
          left: 0;
          top: 0;
          transition-property: top;
          transition-timing-function: linear;
          transition-duration: 1.5s;
          p {
            width: 100%;
            height: 37px;
          }
        }
        span {
          font-size: 17px;
          font-weight: bold;
          line-height: 55px;
        }
      }
    }
    .ranking-List {
      width: 100%;
      height: 298px;
      margin-top: 5px;
      // border: 1px solid green;
      .list-Top {
        width: 110px;
        height: 35px;
        background-image: url("../assets/volunteerTime/topic.png");
        background-repeat: no-repeat;
        margin-left: 19px;
        font-size: 17px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 1px;
      }
      .list-Three {
        // width: 100%;
        height: 98px;
        display: flex;
        // margin: 0 19px;
        padding-left: 19px;
        // border: 1px solid red;
        .active {
          margin-top: -15px;
        }
        .three-Top {
          // display: flex;
          flex: 1;
          position: relative;
          h3 {
            margin-top: 15px;
            width: 24px;
            height: 16px;
            background-color: #12e1c1;
            position: relative;
            margin-left: 35px;
            font-size: 16px;
            font-weight: bold;
          }
          .hexagon::before,
          .hexagon::after {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
          }
          .hexagon::before {
            top: -6px;
            border-bottom: 6px solid #12e1c1;
          }
          .hexagon::after {
            bottom: -6px;
            border-top: 6px solid #12e1c1;
          }
          .middle {
            background-color: #ffbd49;
          }
          .middle::before {
            border-bottom: 6px solid #ffbd49;
          }
          .middle::after {
            border-top: 6px solid #ffbd49;
          }
          .three {
            background-color: #fcc384;
          }
          .three::before {
            border-bottom: 6px solid #fcc384;
          }
          .three::after {
            border-top: 6px solid #fcc384;
          }
          .Number {
            width: 91px;
            height: 60px;
            background-image: url("../assets/volunteerTime/time.png");
            background-repeat: round;
            background-position-y: -5px;
            position: absolute;
            top: 40px;
            left: 0;
            animation: numberAtion 1.5s linear;
            h4 {
              font-size: 13px;
              font-weight: 500;
              // margin-left: -15px;
              // margin-top: 12px;
              letter-spacing: 2px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }
            p {
              font-size: 18px;
              font-weight: bold;
              margin-top: 4px;
              // color: rgba(255, 151, 0, 0.6);
              background: -webkit-linear-gradient(rgba(255, 151, 0, 1), #fff);
              // background: -webkit-linear-gradient(); //为文本元素提供渐变背景。
              -webkit-text-fill-color: transparent; //使用透明颜色填充文本。
              -webkit-background-clip: text; //用文本剪辑背景，用渐变背景作为颜色填充文本
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }
        }
      }
      .list-List {
        // width: 100%;
        height: 181px;
        margin: 0 7px 0 9px;
        display: flex;
        .list-Main {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          // justify-content: flex-start;
          align-content: flex-start;
          justify-content: flex-start;
          .mainlist {
            width: 50%;
            height: 19px;
            margin-bottom: 8px;
            .cityList {
              width: 148px;
              height: 19px;
              text-align: left;
              // overflow: hidden;
              margin-left: 7px;
              // border: 1px solid red;
              position: relative;
              font-size: 13px;
              line-height: 19px;
              background-color: rgba(0, 170, 255, 0.2);
              display: flex;
              cursor: pointer;
              // white-space:nowrap;
              // overflow:hidden;
              // text-overflow:ellipsis;
              span {
                display: inline-block;
                background-image: url("../assets/volunteerTime/listtop.png");
                background-repeat: round;
                width: 32px;
                height: 19px;
                font-size: 15px;
              }
              p {
                width: 110px;
                height: 100%;
                text-align: left;
                // display: inline-block;
                padding-left: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // border: 1px solid red;
              }
            }
            .cityList::before {
              content: "";
              display: block;
              position: absolute;
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              opacity: 1;
              left: -6px;
              border-bottom: 19px solid rgba(0, 170, 255, 0.5);
            }
          }
        }
      }
    }
  }
  .volun-right {
    width: 245px;
    height: 401px;
    margin-left: 11px;
    background-image: url("../assets/volunteerTime/right.png");
    background-repeat: round;
    // border: 1px solid red;
    .right-top {
      width: 110px;
      height: 35px;
      background-image: url("../assets/volunteerTime/topic.png");
      background-repeat: no-repeat;
      margin: 12px 0 0 16px;
      font-size: 17px;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 1px;
    }
    .right-list {
      width: 219px;
      margin: 0 11px 0 15px;
      height: 342px;
      // border: 1px solid red;
      // margin-top: 14px;
      overflow: hidden;
      // overflow-y: scroll;
      ul {
        width: 100%;
        height: auto;
        li {
          width: 100%;
          // border-bottom: 1px dotted #fff;
          // display: flex;
          height: 57px;
          font-size: 13px;
          font-weight: 500;
          overflow: hidden;
          background-image: url("../assets/volunteerTime/line.png");
          background-repeat: no-repeat;
          background-position: 0 53px;
          // justify-content: space-between;
          letter-spacing: 1px;
          font-weight: 500;
          .item-top {
            width: 100%;
            height: 13px;
            display: flex;
            padding-top: 10px;
            // line-height: 20px;
            justify-content: space-between;
          }
          .item-bottom {
            width: 100%;
            height: 16px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            // border: 1px solid red;
            // line-height: 20px;
            margin-top: 8px;
            margin-left: 12px;
            p {
              text-align: left;
            }
          }
          span {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #07b6e2;
            margin-bottom: 3px;
            margin-right: 6px;
            overflow: hidden;
          }
          .left-remind {
            // display: flex;
            display: inline-block;
            width: 70%;
            height: 18px;
            // border: 1px solid red;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          .right-remind {
            color: #7ef7e9;
            margin-right: 3px;
          }
        }
      }
    }
    // .right-list::-webkit-scrollbar{
    //   display:none
    // }
  }
}
@keyframes numberAtion {
  0% {
    transform: scale(0.1);
    // transform: rotate(720deg) scale(0.1);
    opacity: 0;
  }
  50% {
    transform: scale(0.6);
    // transform: rotate(1440deg) scale(0.6);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    // transform: rotate(2160deg) scale(1);
    opacity: 1;
  }
}
</style>
